<template>
  <div class="playable-container">
    <div id="completed" v-if="showCompleted">
      <img style="width: 150px;" src="~@/assets/icons/checkGradient.svg" alt="check" />
      <VueText style="margin-top: 20px;" sizeLevel="6" weightLevel="3">{{
        getPlayableCompletedText
      }}</VueText>
    </div>
    <iframe
      v-if="showIframe"
      id="ommaPlayer"
      :src="getPlayableFactoryUrl"
      style="height:90%; width:100%; position:fixed; inset: 60px 0 0 0;"
    />
  </div>
</template>
<script>
import Playable from '@/services/Api/playable';
import VueText from '@/components/shared/VueText/VueText';
import StorageHelper from '@/utils/storageHelper';
import GameApi from '@/services/Api/gamification';

export default {
  name: 'Playable',
  components: {
    VueText,
  },
  computed: {
    getPlayableInfo() {
      return new StorageHelper({
        id: 'PlayableInfo',
      }).get();
    },
    getPlayableFactoryUrl() {
      return this.getPlayableInfo.referralLink;
    },
    getPlayableCompletedText() {
      return 'Oyun Yap Kazanı Tamamladınız';
    },
  },
  data() {
    return {
      showIframe: false,
      showCompleted: false,
    };
  },
  mounted() {
    this.loadScriptFunc();
  },
  methods: {
    loadScriptFunc() {
      const taskInfo = this.getPlayableInfo;
      window.onmessage = e => {
        if (e.data.type == 'pf_event') {
          const eventName = e.data.name;
          if (eventName == 'start' || eventName == 'end' || eventName == 'cta') {
            const request = {
              totalDuration: 0,
              playDuration: e.data.time,
              status:
                eventName == 'start' ? 1 : eventName == 'cta' ? 4 : eventName == 'end' ? 3 : 2,
              success: eventName == 'end' ? !!e.data.value : false,
              isLinkClicked: eventName == 'cta' ? true : false,
              taskId: taskInfo?.taskId,
              isMarathon: !!taskInfo.marathonId,
            };
            Playable.playableSaveLog(request);

            if (eventName == 'start' || eventName == 'cta') {
              const ommaPlayer = document.getElementById('ommaPlayer');
              ommaPlayer.style.inset = 0;
            } else {
              this.showIframe = false;
              this.showCompleted = true;
            }
          }
        }
      };
      setTimeout(() => {
        this.checkActiveTasks();
      }, 1000);
    },
    async checkActiveTasks() {
      const result = await GameApi.getDoWinTasks(true);
      result?.data?.Data?.inCompletedTasks?.forEach(f => {
        if (f.taskTypeId === 10) {
          this.showIframe = true;
        }
      });

      if (!this.showIframe) {
        this.showCompleted = true;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.playable-container {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  top: 30%;
  #completed {
    display: flex;
    flex-flow: column;
    align-items: center;
  }
}
</style>
